<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse">
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Isi Ringkas</small>
            <h6>
              {{
                state.detail.isi_ringkas === "" ? "-" : state.detail.isi_ringkas
              }}
            </h6>
            <small class="text-muxed">Nomor Surat</small>
            <h6>{{ state.detail.nomor_surat === "" ? "-" : state.detail.nomor_surat }}</h6>
            <small class="text-muxed">Tanggal Surat</small>
            <h6>{{ state.detail.tanggal_surat === "" ? "-" : state.detail.tanggal_surat }}</h6>
            <small class="text-muxed">Tanggal Mulai</small>
            <h6>{{ state.detail.tanggal_mulai === "" ? "-" : state.detail.tanggal_mulai }}</h6>
            <small class="text-muxed">Tanggal Selesai</small>
            <h6>{{ state.detail.tanggal_selesai === "" ? "-" : state.detail.tanggal_selesai }}</h6>
            <small class="text-muxed">Waktu Mulai</small>
            <h6>{{ state.detail.waktu_mulai === "" ? "-" : state.detail.waktu_mulai }}</h6>
            <small class="text-muxed">Waktu Selesai</small>
            <h6>{{ state.detail.waktu_selesai === "" ? "-" : state.detail.waktu_selesai }}</h6>
            <small class="text-muxed">Tempat</small>
            <h6>{{ state.detail.tempat === "" ? "-" : state.detail.tempat }}</h6>
            <small class="text-muxed">Asal Surat</small>
            <h6>{{ state.detail.asal_surat === "" ? "-" : state.detail.asal_surat }}</h6>
            <small class="text-muxed">Penindaklanjut</small>
            <h6>{{ state.detail.penindaklanjut === "" ? "-" : state.detail.penindaklanjut }}</h6>
          </div>

          <div class="col-md-6 pull-left">
            
            <small class="text-muted">Penindaklanjut</small>
            <h6>
              {{
                state.detail.penindaklanjut === ""
                  ? "-"
                  : state.detail.penindaklanjut
              }}
            </h6>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
// import ImageItem from "../../../components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    // ImageItem
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.agenda;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("agenda/STATE", state);
      this.$store.dispatch("agenda/getAgendaById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("agenda/onCancel");
    },
  },
};
</script>
